<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <!-- <vuexy-logo /> -->
      <custom-logo />

      <h2 class="brand-text text-primary ml-1 mr-2">
        {{ navbarTitle }}
      </h2>
      <!-- <b-button
        size="sm"
        variant="primary"
        @click="logout"
      >
        <span class="mr-25 align-middle"> {{ $t("Logout") }}</span>
      </b-button> -->
      <b-dropdown
        :text="getUserData != null ? `${getUserData.firstName} ${getUserData.lastName}` : ''"
        variant="gradient-primary"
      >
        <!-- <b-dropdown-item>
          {{ $t("Company") }}
        </b-dropdown-item>
        <b-dropdown-item>
          {{ $t("Application") }}
        </b-dropdown-item>
        <b-dropdown-item>
          {{ $t("Staff") }}
        </b-dropdown-item> -->
        <!-- <b-dropdown-divider /> -->
        <b-dropdown-item @click="logout">
          {{ $t("Logout") }}
        </b-dropdown-item>
      </b-dropdown>
    </b-link>

    <div class="p-sm-3">
      <div class="w-full text-center">
        <b-row class="mt-5">
          <b-col
            v-for="(item, index) in franchise"
            :key="index"
            cols="4"
          >
            <b-overlay
              :show="isLoading"
              rounded="sm"
            >
              <b-card :title="item.franchiseId.name.toString()">
                <b-img
                  v-if="item.franchiseId.logo"
                  ref="previewEl"
                  rounded
                  :src="item.franchiseId.logo"
                  height="100"
                />
                <b-img
                  v-else
                  ref="previewEl"
                  :src="require('@/assets/images/blank/no_image.png')"
                  rounded
                  height="100"
                />
                <b-button
                  class="mt-2"
                  size="sm"
                  variant="primary"
                  @click="selectFranchise(item)"
                >
                  <span class="mr-25 align-middle"> {{ $t("SELECT") }}</span>
                </b-button>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>

        <!-- <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button> -->

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton,
  BImg,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BAvatar,
//   BDropdownDivider,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'

import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility, franchiseAbility } from '@/libs/acl/config'
import storeModule from '@/store/franchise/selectFranchise'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'selectFranchise'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    CustomLogo,
    BLink,
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // BDropdownDivider,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      isLoading: false,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    franchise() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    themeConfig() {
      return $themeConfig
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }
    // const obj = {
    //   currentPage: this.currentPage,
    //   pageSize: this.perPage,
    // }
    store
      .dispatch(`${STORE_MODULE_NAME}/getFranchise`)
      .then(result => {
        console.log('fetch success :', result)
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  methods: {
    showToast(variant, position, title, icon) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    selectFranchise(data) {
      this.isLoading = true
      if (data.role === 'admin') {
        this.$ability.update(franchiseAbility())
        localStorage.setItem('ability', JSON.stringify(franchiseAbility()))
      } else if (data.role === 'staff') {
        this.$ability.update(data.ability)
        localStorage.setItem('ability', JSON.stringify(data.ability))
      }
      localStorage.setItem('role', data.role)
      localStorage.setItem('franchiseId', data.franchiseId._id)
      localStorage.setItem('franchiseData', JSON.stringify(data.franchiseId))
      this.isLoading = false
      this.$router.replace({ name: 'franchise-dashboard' })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('ability')
      localStorage.removeItem('applicationId')
      localStorage.removeItem('applicationData')
      localStorage.removeItem('companyId')
      localStorage.removeItem('franchiseId')
      localStorage.removeItem('franchiseData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace({ name: 'auth-root-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
